
// Images and Icons
.anImage {
    border-radius: 50%;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 0 10px 8px lightgrey;
    // box-shadow: 0 0 10px 10px white inset;
}

.contactIcon {
    width: 100%;
    max-width: 35px;
}

@media (min-width: 400px) {
    .contactIcon {
        max-width: 50px;
    }
}


.projectIcon {
    // height: auto;
    width: 100%;
    max-width: 100px;
    padding: auto none;
}

.projectIconUC {
    // height: auto;
    width: 100%;
    max-width: 100px;
    padding: auto none;
    filter: blur(.5rem);
}

.footIcon {
    // height: auto;
    width: 100%;
    max-width: 25px;
}

.skillIcon {
    // height: auto;
    width: 100%;
    max-width: 50px;
    padding: auto;
}

.learningIcon {
    width: 75%;
    max-width: 300px;
}


// Banner and Quote text
.quote-text {
    @include font-size(.75rem);
    font-style: italic;
}
.source-text{
    @include font-size(.75rem);  
}

@media (min-width: 1200px) {
    .quote-text {
        font-size: 1.25rem;
    }
    .source-text {
        font-size: 1.25rem;
    }
}